import { Routes } from '@angular/router';
import { CONSTANTS } from '@cue/admin-constants';
import { AccountDoesntExistGuard } from './guards/account-doesnt-exist.guard';
import { AccountExistsGuard } from './guards/account-exists.guard';
import { BasicAuthenticationGuard } from './guards/basic-authentication.guard';
import { UserIsNotLoggedInGuard } from './guards/isnt-logged-in.guard';
import { LicenseNotExistGuard } from './guards/license-not-exist.guard';
import { OAuthCallbackGuard } from './guards/oauth-callback.guard';
import { ResetPasswordGuard } from './guards/reset-password.guard';
import { SetPinGuard } from './guards/set-pin.guard';

export const accountRoutes: Routes = [
  {
    path: 'account',
    loadComponent: () => import('./components/account/account.component').then((x) => x.AccountComponent),
    children: [
      {
        path: '',
        redirectTo: CONSTANTS.paths.accountLogin,
        pathMatch: 'full',
      },
      {
        path: CONSTANTS.paths.oauthCallback,
        loadComponent: () => import('./components/login/login.component').then((x) => x.LoginComponent),
        canActivate: [OAuthCallbackGuard],
      },
      {
        path: CONSTANTS.paths.accountLogin,
        loadComponent: () => import('./components/login/login.component').then((x) => x.LoginComponent),
        canActivate: [AccountExistsGuard, UserIsNotLoggedInGuard],
      },
      {
        path: CONSTANTS.paths.accountRegister,
        loadComponent: () => import('./components/register/register.component').then((x) => x.RegisterComponent),
        canActivate: [AccountDoesntExistGuard],
      },
      {
        path: CONSTANTS.paths.accountResetPassword,
        children: [
          {
            path: '',
            loadComponent: () => import('./components/resetPassword/resetPassword.component').then((x) => x.ResetPasswordComponent),
            canActivate: [AccountExistsGuard, UserIsNotLoggedInGuard, ResetPasswordGuard, BasicAuthenticationGuard],
          },
          {
            path: ':token',
            loadComponent: () => import('./components/resetPassword/resetPassword.component').then((x) => x.ResetPasswordComponent),
            canActivate: [AccountExistsGuard, UserIsNotLoggedInGuard, BasicAuthenticationGuard],
          },
        ],
      },
      {
        path: CONSTANTS.paths.accountForgottenPassword,
        loadComponent: () => import('./components/forgottenPassword/forgottenPassword.component').then((x) => x.ForgottenPasswordComponent),
        canActivate: [AccountExistsGuard, UserIsNotLoggedInGuard, BasicAuthenticationGuard],
      },
      {
        path: CONSTANTS.paths.uploadCertificate,
        loadComponent: () =>
          import('./components/upload-certificate/upload-certificate.component').then((x) => x.UploadCertificateComponent),
        canActivate: [AccountExistsGuard, UserIsNotLoggedInGuard, LicenseNotExistGuard],
      },
      {
        path: CONSTANTS.paths.activateLicenseKey,
        loadComponent: () =>
          import('./components/activate-license-key/activate-license-key.component').then((x) => x.ActivateLicenseKeyComponent),
        canActivate: [AccountExistsGuard, UserIsNotLoggedInGuard, LicenseNotExistGuard],
      },
      {
        path: CONSTANTS.paths.forgottenPIN,
        loadComponent: () =>
          import('./components/forgotten-pin-code/forgotten-pin-code.component').then((x) => x.ForgottenPinCodeComponent),
        canActivate: [AccountExistsGuard, UserIsNotLoggedInGuard],
      },
      {
        path: CONSTANTS.paths.setPIN,
        children: [
          {
            path: '',
            loadComponent: () => import('./components/set-pin-code/set-pin-code.component').then((x) => x.SetPinCodeComponent),
            canActivate: [AccountExistsGuard, UserIsNotLoggedInGuard, SetPinGuard],
          },
          {
            path: ':token',
            loadComponent: () => import('./components/set-pin-code/set-pin-code.component').then((x) => x.SetPinCodeComponent),
            canActivate: [AccountExistsGuard, UserIsNotLoggedInGuard],
          },
        ],
      },
    ],
  },
];
