import { provideState } from '@ngrx/store';
import { provideData } from '@cue/admin-data';
import { CONSTANTS } from '@cue/admin-constants';
import { accountReducer } from './reducers/account.reducers';

export const accountProviders = [
  provideData([
    {
      dataType: CONSTANTS.entity.license,
      loadAllUrl: CONSTANTS.entity.license + CONSTANTS.api.load,
      addUrl: CONSTANTS.entity.license + CONSTANTS.api.add,
    },
  ]),
  provideState({
    name: 'accountState',
    reducer: accountReducer,
  }),
];
