import { AccountState } from './../models/account-state.model';
import { AccountSetUserRole } from './../actions/account.actions';
import { createReducer, on } from '@ngrx/store';

const initialState: AccountState = {
  userRoleId: null,
};

export const accountReducer = createReducer<AccountState>(
  initialState,
  on(AccountSetUserRole, (state, { roleId }) => {
    return {
      ...state,
      userRoleId: roleId,
    };
  }),
);
