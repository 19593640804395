import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap, map } from 'rxjs';
import { AccountService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class LicenseNotExistGuard {
  constructor(
    public router: Router,
    private accountService: AccountService,
  ) {}

  licenseExist(): Observable<boolean> {
    return this.accountService.licenseExist().pipe(
      map((resp) => {
        return resp ? false : true;
      }),
    );
  }

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.licenseExist().pipe(
      tap((x) => {
        if (!x) {
          this.router.navigate(['/']);
        }
      }),
    );
  }
}
