import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SetPinGuard {
  constructor(public router: Router) {}

  hasSetToken(): boolean {
    return !!sessionStorage.getItem('SET_PIN_TOKEN');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.hasSetToken()
      ? true
      : this.router.navigate(['account/login'], {
          queryParams: {
            return_url: state.url,
          },
        });
  }
}
