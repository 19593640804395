import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigService } from '@cue/admin-core';

@Injectable({
  providedIn: 'root',
})
export class BasicAuthenticationGuard {
  constructor(
    public router: Router,
    private configService: ConfigService,
  ) {}

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.configService.value.basicAuthentication) {
      this.router.navigate(['account/login']);
    }
    return this.configService.value.basicAuthentication;
  }
}
