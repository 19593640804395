import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { OauthLoginRequestModel } from '../models';
import { map, tap } from 'rxjs';
import { AccountService } from '../services';

@Injectable({ providedIn: 'root' })
export class OAuthCallbackGuard {
  constructor(
    private router: Router,
    private accountService: AccountService,
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authCode: string = route.queryParams.code;
    const redirectUrl = localStorage.getItem('oauthRedirectUrl');
    const scope = localStorage.getItem('scope');
    const connectionAgentId = parseInt(localStorage.getItem('connectionAgentId'), 10);
    const payload: OauthLoginRequestModel = {
      authorizationCode: authCode,
      redirectUrl,
      scope,
      connectionAgentId,
    };
    return this.accountService.actionOAuthLogin(payload).pipe(
      tap((r) => {
        if (r?.success) {
          sessionStorage.setItem('API_TOKEN', r.data.token);
          sessionStorage.setItem('REFRESH_TOKEN', r.data.refreshToken);
          const parsedJwt = this.accountService.parseJwt(r.data.token);
          this.accountService.setUserRoleStore(parseInt(parsedJwt.roleId, 10));
        } else {
          sessionStorage.removeItem('API_TOKEN');
          sessionStorage.removeItem('REFRESH_TOKEN');
        }
      }),
      map((r) => {
        if (r.success) {
          // todo, storage return url

          const returnUrl = sessionStorage.getItem('returnUrl');
          sessionStorage.removeItem('returnUrl');
          return this.router.parseUrl(returnUrl !== undefined ? returnUrl : '/dashboard');
        } else {
          return this.router.parseUrl(
            '/account/login?error=' + encodeURIComponent('External login failed (' + r.errorCode + ') - ' + r.data),
          );
        }
      }),
    );
  }
}
