import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordGuard {
  constructor(public router: Router) {}

  hasResetToken(): boolean {
    return !!sessionStorage.getItem('RESET_PASSWORD_TOKEN');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.hasResetToken()
      ? true
      : this.router.navigate(['account/login'], {
          queryParams: {
            return_url: state.url,
          },
        });
  }
}
